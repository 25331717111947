import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import Spinner from "react-spinner-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@material-ui/core";
import moment from "moment-timezone";
import { BiDownload } from "react-icons/bi";
import { exportStudents } from "../../queries/student";
import { serverBaseUrl } from "../../shared/BaseUrl";

export default function ExportDailogbox(props: any) {
  const [loading, setloading] = useState(false);
  const [url, SetUrl] = useState();
  const [data, SetDataset] = useState([]);
  const [csvlink, setcsvlink] = useState(false);
  const [csvbutton, SetcsvButton] = useState(true);
  const [DateChange, SetDateChange] = useState({
    startDate: "",
    EndDate: "",
  });
  const [validation, Setvalidation] = useState({
    IsDate: "",
    showDate: false,
  });
  const {
    isOpen = props.isOpen,
    onClose = () => { },
    title = "Export Student List",
    children,
    ...rest
  } = props;

  const headers = [
    { label: "Full Name", key: "full_name" },
    { label: "Mobile", key: "phone" },
    { label: "E-mail", key: "email" },
    { label: "Firebase ID", key: "fcm_token" },
    { label: "Advertising ID", key: "advertisement_id" },
    { label: "College", key: "college_name" },
    { label: "Year of Study", key: "year_of_study" },
    { label: "Country", key: "country" },
    { label: "State", key: "state" },
    { label: "IDcard status", key: "is_idcard_verified" },
    { label: "College ID uploaded status ", key: "id_card_path" },
    { label: "Total watch History ", key: "total_duration" },
    { label: "OS", key: "last_device_platform" },
    { label: "mbbs/md", key: "mbbs_md" },
    { label: "Current Appversion", key: "app_version" },
    { label: "Last used date and Time", key: "last_used_time" },
    { label: "Joined Date", key: "created_at" },
    { label: "Account Status", key: "is_disabled" },
    { label: "Batch", key: "batch" },
    { label: "Trial Period", key: "is_trial" },
  ];

  const handleClose = (event: any) => {
    SetDateChange({
      startDate: "",
      EndDate: "",
    });
    SetcsvButton(true);
    setcsvlink(false);
    onClose(event);
  };
  useEffect(() => {
    if (validation.showDate) {
      Setvalidation({
        ...validation,
        IsDate: "",
        showDate: false,
      });
    }
  }, [DateChange]);
  // useEffect(() => {
  //   setDataList(props.TotalList);
  // }, [props.TotalList]);

  useEffect(() => {
    if (
      props.isOpen &&
      DateChange.startDate != "" &&
      DateChange.EndDate != ""
    ) {
      DataExport();
    }
  }, [props.isOpen, DateChange]);

  const fetchdata = (dataList: any) => {
    if (dataList.length >= 0) {
      const data = dataList?.map((student: any, index: any) => {
        let data = student;
        if (student.created_at) {
          var created_at = moment(student.created_at)
            .tz("Asia/Kolkata")
            .format("YYYY-MM-DD");
          //student.created_at = created_at;
          data = { ...data, created_at };
        }
        if (student.last_used_time) {
          var last_used_time = moment(student.last_used_time)
            .tz("Asia/Kolkata")
            .format("YYYY-MM-DD hh:mm A");
          //student.last_used_time = last_used_time;
          data = { ...data, last_used_time };
        }
        if (student.is_idcard_verified) {
          var is_idcard_verified = "Verified";
          data = { ...data, is_idcard_verified };
        } else {
          var is_idcard_verified = "Not verified";
          data = { ...data, is_idcard_verified };
        }
        if (student.total_duration) {
          var a = student.total_duration.split(":");
          var minutes = +a[0] * 60 + +a[1];
          var min = minutes + " min";

          data = { ...data, min };
        }
        if (student.hasOwnProperty("mbbs_md")) {
          var mbbs_md = "-----";
          if (student.mbbs_md) {
            mbbs_md = student.mbbs_md?.toString();
          }
          data = { ...data, mbbs_md };
        }
        if (student.hasOwnProperty("id_card_path")) {
          var id_card_path = "Yes";
          data = { ...data, id_card_path };
        } else {
          var id_card_path = "No";
          data = { ...data, id_card_path };
        }
        if (student.is_disabled) {
          var is_disabled = "Deactive";
          data = { ...data, is_disabled };
        } else {
          var is_disabled = "Activate";
          data = { ...data, is_disabled };
        }
        return data;
      });
      console.log("data", data);
      SetDataset(data);
      setloading(false);
      setcsvlink(true);
    }
  };

  const StartDateChange = (e: any) => {
    SetDateChange({ ...DateChange, startDate: e.target.value });
  };
  const EndDateChange = (e: any) => {
    SetDateChange({ ...DateChange, EndDate: e.target.value });
  };
  const DataExport = async () => {
    SetcsvButton(false);
  };
  const dataExportHandler = (event: any) => {
    if (DateChange.startDate !== "" && DateChange.EndDate !== "") {
      console.log(
        "DateChange.EndDat DateChange.startDate",
        DateChange.EndDate,
        ">=",
        DateChange.startDate
      );

      if (DateChange.EndDate >= DateChange.startDate) {
        setloading(true);
        const TotalstudentData = async () => {
          let students = await exportStudents(
            DateChange.startDate,
            DateChange.EndDate
          );
          if (await students) {
            console.log("students", students);
            // SetUrl(students);
            // setloading(false);
            // setcsvlink(true);
            // SetStudentList(await students);
            fetchdata(await students);
          } else {
          }
        };
        TotalstudentData();

        // handleClose(event);
      } else {
        Setvalidation({
          ...validation,
          IsDate: "End date Should be greater than Start Date ",
          showDate: true,
        });
      }

      // const result = dataset.filter(
      //   (stud: any) =>
      //     stud.created_at >= DateChange.startDate &&
      //     stud.created_at <= DateChange.EndDate
      // );
      // if (result !== []) {
      //   SetStudentData(result);
      // }
    } else {
      Setvalidation({
        ...validation,
        IsDate: "Please Choose Date",
        showDate: true,
      });
    }
  };
  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {csvlink ? (
          <div className="donwload-csv">
            {/* <a href={serverBaseUrl + url} download>
              <Button
                variant="contained"
                color="primary"
                href={serverBaseUrl + url}
              >
                <BiDownload />
              </Button>
            </a> */}
            <CSVLink data={data} headers={headers} filename="StudentList.csv">
              <button onClick={dataExportHandler}>
                Export <BiDownload />{" "}
              </button>
            </CSVLink>
          </div>
        ) : (
          <table>
            <tr>
              <th>
                {" "}
                <TextField
                  id="date"
                  label="Start Date"
                  type="date"
                  onChange={StartDateChange}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </th>
              <th style={{ paddingLeft: "4em" }}>
                {" "}
                <TextField
                  id="date"
                  label="End Date"
                  type="date"
                  onChange={EndDateChange}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </th>
            </tr>
            {validation.showDate && (
              <tr>
                <td>
                  <span style={{ color: "red" }}>{validation.IsDate}</span>
                </td>
              </tr>
            )}
          </table>
        )}
      </DialogContent>
      <DialogActions>
        {!csvlink && (
          <>
            {" "}
            <button onClick={handleClose}>Cancel</button>
            {loading ? (
              // <Spinner style={{ color: "red" }} />
              <span style={{ color: "red" }}>Please wait...</span>
            ) : (
              <button onClick={dataExportHandler} disabled={csvbutton}>
                Submit
              </button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
