import { TablePagination } from "@material-ui/core";
import MaterialTable, { Column } from "material-table";
import moment from "moment-timezone";
import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  getAllSubscription,
  getSubscriptionCount,
} from "../../queries/student";
import { Issue } from "../../shared/modelTypes";
import { Passcode } from "./Passcode";
import {
  Button,
  Container,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";

export const Subscription = () => {
  const [passCode, setPassCode] = useState<string>("");
  const [show, setShow] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isError, setIsError] = useState(false);
  const [type, setType] = useState();
  const handleClose = () => {
    if (passCode === "phoenix125") {
      setShow(false);
    }
    setIsError(true);
  };

  const { data: count } = useQuery(
    "students/subscription-count",
    async () => await getSubscriptionCount(),
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const { isLoading, data: allSubscription = [] } = useQuery(
    ["students/all-subscriptions", page, rowsPerPage],
    async () => await getAllSubscription(type, rowsPerPage, page + 1),
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
  console.log(count?.total_count);
  const columnDefintion: Column<Issue>[] = [
    {
      title: "Name",
      field: "profile.full_name",
    },
    {
      title: "Email",
      field: "login_email",
    },
    {
      title: "Phone",
      field: "login_phone",
    },
    {
      title: "College",
      field: "college_name",
    },
    {
      title: "Batch",
      field: "batch",
    },
    {
      title: "Subject",
      field: "subject_name",
    },
    {
      title: "Coupon Code",
      field: "coupon_code",
    },
    {
      title: "Amount",
      field: "order_amount",
    },
    {
      title: "Order ID",
      field: "razorpay_order_id",
    },
    {
      title: "Transaction ID",
      field: "razorpay_payment_id",
    },
    {
      title: "Purchase Date",
      field: "purchase_date",
      render: ({ purchase_date }) => {
        return moment(purchase_date)
          .tz("Asia/Kolkata")
          .format("YYYY-MM-DD hh:mm A");
      },
    },
    {
      title: "Expiry Date",
      field: "valid_through",
      render: ({ valid_through }) => {
        return moment(valid_through)
          .tz("Asia/Kolkata")
          .format("YYYY-MM-DD hh:mm A");
      },
    },
  ];

  return show === false ? (
    <Container maxWidth="xl">
      <Grid container spacing={2} style={{ padding: "2em" }} direction="column">
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs>
          <Button
            color="primary"
            variant="contained"
            component={Link}
            to={"/cbme"}
          >
            CBME
          </Button>
        </Grid>
        <Grid item xs>
          <MaterialTable
            isLoading={isLoading}
            title="Individual Subscription"
            columns={columnDefintion.map((c) => ({
              ...c,
              tableData: undefined,
            }))}
            data={allSubscription}
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  rowsPerPageOptions={[5, 10, 20]}
                  rowsPerPage={rowsPerPage}
                  count={count?.total_count || 0}
                  page={page}
                  onChangePage={(e, incomingPage) => {
                    setPage(incomingPage);
                  }}
                  onChangeRowsPerPage={(event) => {
                    props.onChangeRowsPerPage(event);
                    setRowsPerPage(Number(event.target.value));
                    setPage(0);
                  }}
                />
              ),
            }}
          />
        </Grid>
      </Grid>
    </Container>
  ) : (
    <Passcode
      passCode={passCode}
      isErrors={isError}
      setPassCode={setPassCode}
      handleClose={handleClose}
    />
  );
};
