import MomentUtils from "@date-io/moment";
import {
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useMutation, useQueryClient } from "react-query";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router";
import {
  createConfiguration,
  editConfiguration,
} from "../../../../queries/configuration";
import { HomeConfigurationItems } from "../../../../shared/modelTypes";
import { GetAllColleges } from "../../coupons/formOptions";
import { useLocation } from "react-router-dom";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { dark } from "@material-ui/core/styles/createPalette";

type PropsTypes = {
  rowData: HomeConfigurationItems | null;
};

export const AddConfiguration = () => {
  const location = useLocation();
  const rowData = location.state;
  const queryClient = useQueryClient();
  const history = useHistory();
  const [validation, setValidation] = useState({
    title: "",
    isenable: "",
    from: "",
    to: "",
  });

  const getYears = () => {
    let currentYear = new Date().getFullYear()
    let years: any[] = [];
    let startYear = 2014;
    while (startYear <= currentYear) {
      let year = startYear++;
      years.push({
        value: year, name: year.toString(),
      });
    }
    years.unshift({ value: "id", name: "All" })
    return years;
  }

  const collegeOptions = GetAllColleges();
  const batchOptions: any = getYears();

  const [selectedCollege, setSelectedCollege] = useState([
    { value: "", name: "" },
  ]);
  const [selectedBatches, setSelectedBatches] = useState([
    { value: "", name: "" },
  ]);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const date = new Date();
  const formik = useFormik<HomeConfigurationItems>({
    initialValues: rowData
      ? (rowData as HomeConfigurationItems)
      : ({
        is_enabled: false,
        applicable_colleges: ["empty"],
        valid_from: moment(date)
          .tz("Asia/Kolkata")
          .format("YYYY-MM-DD hh:mm A"),
        valid_through: moment(date)
          .tz("Asia/Kolkata")
          .format("YYYY-MM-DD hh:mm A"),
      } as HomeConfigurationItems),
    onSubmit: async (values) => {
      const payload = { ...values };
      //@ts-ignore\
      payload.valid_from = new Date(values.valid_from).toISOString();
      //@ts-ignore
      payload.valid_through = new Date(values.valid_through).toISOString();

      values.applicable_colleges?.find((element) => {
        if (element === "id") {
          values.applicable_colleges = [];
          collegeOptions.map((id) => {
            if (id.value !== "id") {
              values.applicable_colleges.push(id.value);
            }
          });
        }
      });
      payload.applicable_colleges = values.applicable_colleges?.filter(function (e) {
        return e !== "empty";
      });

      values.applicable_batches?.find((element) => {
        if (element === "id") {
          values.applicable_batches = [];
          batchOptions.map((id: any) => {
            if (id.value !== "id") {
              values.applicable_batches.push(id.value);
            }
          });
        }
      });

      payload.applicable_batches = values.applicable_batches?.filter(function (e) {
        return e !== "empty";
      });

      if (values.id) {
        return mutUpdateConfiguration(payload);
      } else {
        return mutCreateConfiguration(payload);
      }
    },
  });

  const { handleChange, values, setFieldValue, handleSubmit } = formik;

  const { mutateAsync: mutCreateConfiguration } = useMutation(
    createConfiguration,
    {
      onSuccess: (newCoupon) => {
        queryClient.setQueryData<Partial<HomeConfigurationItems>[]>(
          "Configuration",
          (prev) => [...(prev || []), newCoupon]
        );
        history.goBack();
      },
    }
  );

  const { mutateAsync: mutUpdateConfiguration } = useMutation(
    editConfiguration,
    {
      onSuccess: (parms) => {
        queryClient.invalidateQueries();
        history.goBack();
      },
    }
  );

  return (
    <Container maxWidth="xl">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid
          container
          spacing={2}
          style={{ padding: "2em" }}
          direction="column"
        >
          <Grid item xs>
            <Typography variant="h6">
              {rowData ? "Edit Configuration" : "Add New Configuration"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <TextField
                  fullWidth
                  label="Title"
                  variant="outlined"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                />
                {validation?.title && <span>{"Title required"}</span>}
              </Grid>
              <Grid item xs={12} lg={3}>
                <TextField
                  fullWidth
                  label="Description"
                  variant="outlined"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <DateTimePicker
                  fullWidth
                  autoOk={true}
                  label="Valid From"
                  placeholder="GG.AA.YYYY SS:DD"
                  name="valid_from"
                  onChange={(date) =>
                    setFieldValue(
                      "valid_from",
                      moment(date)
                        .tz("Asia/Kolkata")
                        .format("YYYY-MM-DD hh:mm A")
                    )
                  }
                  value={values.valid_from}
                  inputVariant="outlined"
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <DateTimePicker
                  fullWidth
                  autoOk={true}
                  label="Valid Through"
                  format="YYYY-MM-DD hh:mm A"
                  name="valid_through"
                  onChange={(date) =>
                    setFieldValue(
                      "valid_through",
                      moment(date)
                        .tz("Asia/Kolkata")
                        .format("YYYY-MM-DD hh:mm A")
                    )
                  }
                  value={values.valid_through}
                  inputVariant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs>
            <Grid item xs>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={3}>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={collegeOptions}
                    disableCloseOnSelect
                    value={
                      values.applicable_colleges
                        ? collegeOptions.filter((item: any) =>
                          values.applicable_colleges.includes(item.value)
                        )
                        : []
                    }
                    onChange={(_, value) => {
                      setFieldValue(
                        "applicable_colleges",
                        value.map(({ value }: any) => value)
                      );
                      setSelectedCollege(value);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(option, state) => {
                      const selectCollegeIndex = selectedCollege.findIndex(
                        (subject) => subject.name.toLowerCase() === "all"
                      );
                      if (selectCollegeIndex > -1) {
                        state.selected = true;
                      }
                      return (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={state.selected}
                          />
                          {option.name}
                        </React.Fragment>
                      );
                    }}
                    renderInput={(params) => {
                      const { InputProps, ...restParams } = params;
                      const { startAdornment, ...restInputProps } = InputProps;
                      return (
                        <TextField
                          {...restParams}
                          label={"Applicable Colleges"}
                          variant="outlined"
                          InputProps={{
                            ...restInputProps,
                            startAdornment: (
                              <div
                                id=""
                                style={{
                                  overflow: "scroll",
                                  height: "150px",
                                  overflowX: "hidden",
                                  width: "500px",
                                }}
                              >
                                {startAdornment}
                              </div>
                            ),
                          }}
                        />
                      );
                    }}
                  />

                </Grid>

              </Grid>
            </Grid>

            <Grid item xs>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={3}>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={batchOptions}
                    disableCloseOnSelect
                    value={
                      values.applicable_batches
                        ? batchOptions.filter((item: any) =>
                          values.applicable_batches.includes(item.value)
                        )
                        : []
                    }
                    onChange={(_, value) => {
                      setFieldValue(
                        "applicable_batches",
                        value.map(({ value }: any) => value)
                      );
                      setSelectedBatches(value);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(option, state) => {
                      const selectBatchIndex = selectedBatches.findIndex(
                        (subject) => subject.name.toLowerCase() === "all"
                      );
                      if (selectBatchIndex > -1) {
                        state.selected = true;
                      }
                      return (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={state.selected}
                          />
                          {option.name}
                        </React.Fragment>
                      );
                    }}
                    renderInput={(params) => {
                      const { InputProps, ...restParams } = params;
                      const { startAdornment, ...restInputProps } = InputProps;
                      return (
                        <TextField
                          {...restParams}
                          label={"Applicable Batches"}
                          variant="outlined"
                          InputProps={{
                            ...restInputProps,
                            startAdornment: (
                              <div
                                id=""
                                style={{
                                  overflow: "scroll",
                                  height: "150px",
                                  overflowX: "hidden",
                                  width: "500px",
                                }}
                              >
                                {startAdornment}
                              </div>
                            ),
                          }}
                        />
                      );
                    }}
                  />

                </Grid>

              </Grid>
            </Grid>

            {/* <Grid item xs>
              <Grid container spacing={4}>
                <Grid item xs={12} lg={3}>
                  <DatePicker
                    fullWidth
                    format="DD/MM/yyyy"
                    label="Valid From"
                    name="valid_from"
                    views={["year", "month", "date"]}
                    onChange={(date) =>
                      setFieldValue("valid_from", date?.endOf("date"))
                    }
                    value={values.valid_from}
                    inputVariant="outlined"
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <DatePicker
                    fullWidth
                    format="DD/MM/yyyy"
                    label="Valid Through"
                    name="valid_through"
                    views={["year", "month", "date"]}
                    onChange={(date) =>
                      setFieldValue("valid_through", date?.endOf("date"))
                    }
                    value={values.valid_through}
                    inputVariant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid> */}
            <Grid item xs>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.is_enabled}
                        onChange={handleChange}
                        name="is_enabled"
                        color="primary"
                        value={values.is_enabled}
                      />
                    }
                    label="Is Enabled?"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} style={{ paddingTop: "6em" }}>
              {" "}
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleSubmit()}
              >
                Save
              </Button>{" "}
              <Button
                color="primary"
                variant="contained"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </Container>
  );
};
