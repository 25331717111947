import { MutationFunction } from "react-query";
import { api } from "../api";
import {
  InCompleteRegistrations,
  Login,
  Student,
  StudentSubscriptions,
} from "../shared/modelTypes";

export const getStudentProfile = (
  user: string | null | undefined | number = undefined
) => {
  return api.get("students/profile", user ? { student: user } : undefined);
};

export const getStudentSubscription = (
  user: string | null | undefined | number = undefined,
  type: string | null | undefined | number = undefined
) => {
  return api.get(
    "students/subscription",
    user ? { student: user, type } : undefined
  );
};

export const createStudentSubscription: MutationFunction<
  StudentSubscriptions,
  {
    id: number;
    type: string | undefined;
    subscription: Partial<StudentSubscriptions>;
  }
> = async ({ id, subscription, type = undefined }) => {
  subscription.student_id = id;

  return api.post(`students?type=${type}`, undefined, subscription);
};

export const updateStudentSubsription: MutationFunction<
  StudentSubscriptions,
  {
    id: number;
    type: string | undefined;
    payload: Partial<StudentSubscriptions>;
  }
> = async ({ id, payload, type = undefined }) => {
  return api.patch(`students/${id}?type=${type}`, undefined, payload);
};

export const deleteStudentSubsription: MutationFunction<
  StudentSubscriptions,
  Partial<StudentSubscriptions>
> = async (subscription) => {
  return api.delete(`students/subscription/${subscription.id}`);
};

export const updateStudentStatus: MutationFunction<
  Login,
  { id: number; payload: Partial<Login> }
> = async ({ id, payload }) => {
  return api.patch(`students/status/${id}`, undefined, payload);
};

export const updateStudentTrail: MutationFunction<
  Login,
  { id: number; payload: Partial<Login> }
> = async ({ id, payload }) => {
  return api.patch(`students/update-trial `, undefined, payload);
};

export const updateStudentIDcard: MutationFunction<
  Login,
  { id: number; payload: Partial<Login> }
> = async ({ id, payload }) => {
  return api.patch(`/students/verify-idcard/${id}`, undefined, payload);
};
export const getStudentDevices = (
  user: string | null | undefined | number = undefined
) => {
  return api.get("students/devices", user ? { student: user } : undefined);
};

export const getStudentBookemark = (
  user: string | null | undefined | number = undefined
) => {
  return api.get("students/bookemarks", user ? { student: user } : undefined);
};

export const getStudentWatchHistory = (
  user: string | null | undefined | number = undefined
) => {
  return api.get("students/history", user ? { student: user } : undefined);
};

export const getInCompleteLogin = () => {
  return api.get("students/incomplete_login");
};

export const getInCompleteRegistration = () => {
  return api.get("students/incomplete_registration");
};

export const getAllIsuues = () => {
  return api.get("students/issue");
};

export const getAllStudents = (
  limit: number = 0,
  page: number = 1,
  searchkey: string = ""
) => {
  return api.get("students/all", { limit, page, searchkey });
};

export const getAllStudentsList = (params = undefined) => {
  return api.get("students/all-list", { searchkey: params });
};

export const exportStudents = (fromDate: string = "", toDate: string = "") => {
  return api.get("students/export", { fromDate, toDate });
};

export const getStudentsCount = () => {
  return api.get("students/count");
};

export const deleteStudent: MutationFunction<Student, Partial<Student>> =
  async (student) => {
    return api.delete(`students/${student.id}`);
  };

export const getSubscriptionCount = () => {
  return api.get("students/subscription-count");
};
export const getCBMCount = () => {
  return api.get("students/subscription-count", { type: "cbme" });
};

export const getAllSubscription = (
  type: string = "",
  limit: number = 0,
  page: number = 1
) => {
  return api.get("students/all-subscriptions", { type, limit, page });
};
