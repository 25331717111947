import MaterialTable, { Column } from "material-table";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getAllTopics } from "../../queries/categories";
import { getAllRawVideos } from "../../queries/media-source";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import {
  createVideo,
  deleteVideo,
  getAllVideos,
  updateVideo,
} from "../../queries/video";
import { Button, Tooltip } from "@material-ui/core";
import { S3BucketType, Topic, VideoType } from "../../shared/modelTypes";
import Select from "react-select";
import { reactSelectCustomStyle } from "../../theme/theme";
import { getAllS3Bucket } from "../../queries/s3bucket";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
export const Video = () => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const [isHovering, setIsHovering] = useState(false);

  const { isLoading, data: videos = [] } = useQuery(
    "media/videos",
    getAllVideos,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
  const queryClient = useQueryClient();

  const { data: mediaSources = [] } = useQuery(
    "media/media_sources",
    getAllRawVideos,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const { data: topics = [] } = useQuery<Topic[]>(
    ["topics", undefined],
    () => getAllTopics(undefined),
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const { data: s3Buckets = [] } = useQuery<S3BucketType[]>(
    "config/s3_buckets",
    getAllS3Bucket,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const videoLibrary = (rowData: any) => {
    // history.push(`${url}/add-videos`, rowData);
    history.push(`${url}/${rowData.id}`);
  };
  const columnDefintion: Column<VideoType>[] = [
    {
      render: (rowData) => (
        <Tooltip title={"Integrated Videos"}>
          <Button
            color="default"
            variant="text"
            onClick={() => videoLibrary(rowData)}
          >
            <VideoLibraryIcon />
          </Button>
        </Tooltip>
      ),
    },
    {
      title: "Media Source",
      field: "media_source_id",
      lookup: mediaSources.reduce(
        (acl: any, curr: { id: any; title: any }) => ({
          ...acl,
          [curr.id]: curr.title,
        }),
        {}
      ),
      validate: (rowData) => Boolean(rowData.media_source_id),
      editComponent: ({ onChange }) => {
        return (
          <Select
            options={mediaSources.map((media: { id: any; title: any }) => ({
              value: media.id,
              label: media.title,
            }))}
            styles={reactSelectCustomStyle}
            onChange={(event: any) => {
              if (!event) {
                return;
              }
              onChange(event.value);
            }}
            maxMenuHeight={220}
          />
        );
      },
    },
    {
      title: "Topic",
      field: "topic_id",
      lookup: topics.reduce(
        (acl, curr) => ({ ...acl, [curr.id]: curr.title }),
        {}
      ),
      editComponent: ({ onChange }) => {
        return (
          <Select
            options={topics.map((topic: { id: any; title: any }) => ({
              value: topic.id,
              label: topic.title,
            }))}
            styles={reactSelectCustomStyle}
            onChange={(event: any) => {
              if (!event) {
                return;
              }
              onChange(event.value);
            }}
            menuPlacement="auto"
          />
        );
      },
    },
    {
      title: "Type",
      field: "video_type",
      lookup: {
        theory: "Theory",
        practical: "Practical",
      },
      validate: (rowData) => Boolean(rowData.video_type),
    },
    {
      title: "Title",
      field: "title",
      validate: (rowData) => Boolean(rowData.title),
    },

    {
      title: "Description",
      field: "description",
    },

    {
      title: "Is free?",
      field: "is_free",
      type: "boolean",
      initialEditValue: true,
    },
    {
      title: "Is Exclusive?",
      field: "is_exclusive",
      type: "boolean",
      initialEditValue: true,
    },
    {
      title: "Is Listed?",
      field: "is_listed",
      type: "boolean",
      initialEditValue: true,
    },
    {
      title: "List order",
      field: "list_order",
      validate: (rowData) => Boolean(rowData.list_order),
      type: "numeric",
    },
    {
      title: "Thumbnail & Flash Note Bucket",
      field: "thumbnail_bucket_id",
      lookup: s3Buckets.reduce(
        (acl: any, curr: { id: any; title: any }) => ({
          ...acl,
          [curr.id]: curr.title,
        }),
        {}
      ),
      // validate: (rowData) => Boolean(rowData.thumbnail_bucket_id),
      editComponent: ({ onChange }) => {
        return (
          <Select
            options={s3Buckets.map((bucket: { id: any; title: any }) => ({
              value: bucket.id,
              label: bucket.title,
            }))}
            styles={reactSelectCustomStyle}
            onChange={(event: any) => {
              if (!event) {
                return;
              }
              onChange(event.value);
            }}
            menuPlacement="auto"
          />
        );
      },
    },
    {
      title: "Thumbnail File path",
      field: "thumbnail_file_path",
      // validate: (rowData) => Boolean(rowData.thumbnail_file_path),
    },
    {
      title: "Flash Note File path",
      field: "flash_note_filepath",
    },

    {
      title: "Web Url",
      field: "web_view_url",
    },
    {
      title: "Web Url Description",
      field: "web_view_description",
    },
  ];

  const { mutateAsync: mutCreateVideo } = useMutation(createVideo, {
    onSuccess: (newSystem) => {
      queryClient.setQueryData<Partial<VideoType>[]>("media/videos", (prev) => [
        ...(prev || []),
        newSystem,
      ]);
    },
  });

  const { mutateAsync: mutDeleteVideo } = useMutation(deleteVideo, {
    onSuccess: (params) => {
      queryClient.invalidateQueries();
    },
  });

  const { mutateAsync: mutUpdateVideo } = useMutation(updateVideo, {
    onSuccess: (updatingSystem) => {
      queryClient.invalidateQueries();
    },
  });

  return (
    <MaterialTable
      isLoading={isLoading}
      data={videos}
      title="Media videos"
      columns={columnDefintion.map((c) => ({
        ...c,
        tableData: undefined,
      }))}
      editable={{
        onRowAdd: mutCreateVideo,
        onRowUpdate: (updatingVideo) =>
          mutUpdateVideo({
            id: updatingVideo.id,
            payload: updatingVideo,
          }),
        onRowDelete: mutDeleteVideo,
      }}
    />
  );
};
